<template>
  <div id="news" class="solutions-area section-width ptb-100">
    <div class="container">
      <div class="section-title">
        <h2>{{ $t("navbar.menu_3_1") }}</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum
          suspendisse ultrices gravida.
        </p>
      </div>
      <div class="port-filter">
        <a
          :class="['filter', { active: selectGroupId == 0 }]"
          @click="getNewsGroupList(0)"
          >{{ $t("class") }}</a
        >
        <a
          :class="['filter', { active: selectGroupId == item[0] }]"
          v-for="(item, index) in newsGroupNameList"
          :key="index"
          @click="getNewsGroupList(item[0])"
          >{{ item[1] }}</a
        >
      </div>
      <div
        class="wow fadeIn pb-30 news-box"
        v-for="(item, index) in newsGroupTitleList"
        :key="index"
        @click="showNewsModel(item.announcement_message_id)"
      >
        <div class="row animated fadeInUp">
          <div class="col-md-2 col-sm-2 news-class">
            <div :class="['news-class-bg' + item.announcement_group_id]">
              {{ item.group_name }}
            </div>
          </div>
          <div class="col-md-2 col-sm-2 news-date">
            <div>
              {{ item.post_date }}
            </div>
          </div>
          <div class="col-md-8 col-sm-8 news-title">
            <img
              src="~assets/project_images/pic/newtop.webp"
              v-if="item.topping == 1"
            />
            <a>{{ item.message_title }}</a>
          </div>
        </div>
      </div>
      <div :class="['row', 'text-center']" v-show="isShowNextPage">
        <div class="col-lg-12 read-more">
          <button
            class="btn btn-warning"
            @click="getNewsGroupList(selectGroupId)"
          >
            {{ $t("pageBtn") }}<i class="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
    <NewsModel :newsId="2" :NewsModelList="NewsModelList" />
  </div>
</template>

<script>
import DateFormat from "dateformat";
import NewsModel from "components/DefaultHome/KECC/Common/NewsModal";
export default {
  name: "News",
  components: {
    NewsModel,
  },
  data() {
    return {
      newsList: null,
      showPageNumber: window.innerWidth >= 991 ? 4 : 1,
      NewsModelList: [],
      waitOnCallModel: false,
      apiUrl: `${process.env.VUE_APP_API_URL}/announcement/`,
      newsGroupNameList: [],
      newsGroupTitleList: [],
      selectGroupId: -1,
      isShowNextPage: true,
      page: 1,
    };
  },
  async mounted() {
    const that = this;
    window.addEventListener("resize", () => {
      that.showPageNumber = window.innerWidth >= 991 ? 4 : 1;
    });
    await that.getNewsGroupName();
    await that.getNewsGroupList();
    if (this.$route.params.id != undefined) {
      let message_id = this.$route.params.id;
      that.showNewsModel(message_id);
    }
    this.waitOnCallModel = true;
  },
  methods: {
    async getNewsGroupName() {
      let api = `${this.apiUrl}group?announcement_id=5&language_id=${this.$i18n.locale}`;
      Promise.all([
        await this.axios.get(api).then((response) => {
          let data = response.data;
          for (let arr in data) {
            this.newsGroupNameList.push([
              data[arr]["announcement_group_id"],
              data[arr]["group_name"],
            ]);
          }
        }),
      ]);
    },
    getNewsGroupList(group_id = 0) {
      let limit = 10;
      this.page = this.selectGroupId != group_id ? 1 : this.page + 1;
      let api = `${this.apiUrl}message_list?announcement_id=5&language_id=${this.$i18n.locale}&auto_replace=false&announcement_group_id=${group_id}&limit=${limit}&page=${this.page}&filters[is_hidden]=0`;
      this.axios.get(api).then((response) => {
        let data = response.data["list"];
        for (let i = 0; i < data.length; i++) {
          data[i].group_name = this.newsGroupNameList[
            data[i].announcement_group_id - 1
          ][1];
          data[i].post_date = DateFormat(
            new Date(Date.parse(data[i].post_date.replace(/-/g, "/"))),
            "yyyy-mm-dd"
          );
        }
        this.isShowNextPage = limit * this.page < response.data.count.all;
        if (this.selectGroupId == group_id) {
          this.newsGroupTitleList = this.newsGroupTitleList.concat(data);
        } else {
          this.newsGroupTitleList = data;
        }
        this.selectGroupId = group_id;
      });
    },
    async showNewsModel(message_id) {
      this.$root.$emit("bv::show::modal", "modal-news-2");
      let apiUrl = `${this.apiUrl}message?announcement_message_id=${message_id}&language_id=${this.$i18n.locale}&language_format=value`;
      await this.axios.get(apiUrl).then((response) => {
        this.NewsModelList = response.data;
      });
    },
  },
};
</script>

<style scoped>
#news {
  min-height: 800px;
}

#news .container {
  max-width: 900px;
}
.news-title img {
  position: relative;
  bottom: 5px;
  width: 25px;
  right: 6px;
}
.news-title a {
  cursor: pointer;
  font-size: 16px;
}
.news-title a:hover {
  color: #000066;
}
.page-section {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}
.filter.active {
  color: #000033;
  font-weight: bold;
}
.filter.active::after {
  font-size: 14px;
  content: " ▶";
}
.port-filter {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0 0 45px 0;
  text-transform: uppercase;
  /* text-align: center; */
  letter-spacing: 2px;
  color: #555;
  cursor: default;
}

.port-filter {
  font-size: 1.125em;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.port-filter a:first-child {
  border-left: none;
}

.port-filter a {
  min-width: 120px;
  text-align: center;
  display: inline-block;
  margin: 10px 0;
  padding-left: 10px;
  color: #999;
  text-decoration: none;
  border-left: 1px dashed #dadada;
  cursor: pointer;
}

.port-filter a.active {
  border-bottom: none;
  border-color: #dadada;
  cursor: default;
}

.file-type-icon {
  width: 30px;
}

.clearfix:before,
.clearfix:after,
.row:before,
.row:after {
  content: "\0020";
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

.row:after,
.clearfix:after {
  clear: both;
}

.row,
.clearfix {
  zoom: 1;
}

.news-class {
  text-align: center;
}

.news-class {
  text-align: center;
}
.news-class div {
  padding: 2px 10px;
  border-radius: 5px;
}
.news-class-bg1 {
  background-color: #000033;
}

.news-class-bg2 {
  background-color: #000066;
}

.news-class-bg3 {
  background-color: #99ffff;
  color: #000066;
}

.news-class-bg4 {
  background-color: #028090;
}

.news-class-bg5 {
  background-color: #6369d1;
}

.news-class-bg6 {
  background-color: #999999;
}

.news-title {
  text-align: left;
}

.news-date {
  text-align: center;
}

.news-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.news-title a {
  display: inline;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.row:after,
.clearfix:after {
  clear: both;
}

.clearfix:before,
.clearfix:after,
.row:before,
.row:after {
  content: "\0020";
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

.main-area {
  margin: 0 0 30px 0;
}

.cis-cont {
  position: relative;
  margin: 0 auto;
  width: 300px;
  padding-left: 100px;
}

.icon-home {
  position: absolute;
  /* top: 25px; */
  left: -15px;
  font-size: 2.188em;
}

.icon-home,
#tab-1 a:hover {
  color: #7e8082;
}

.content-text.white,
.menuborder:hover,
.parentMenu,
.news-class,
.banner-text,
.top-bar-section li a:hover,
.black-header #main-menu .navbar .nav > li > a,
.parentMenu,
.post-prev-info,
.top-bar,
.affix .top-bar,
.banner-text a {
  color: #fff;
}

.news-icon {
  width: 100px;
}

@media (max-width: 1200px) {
  /* Extra large devices (large desktops, 1200px and down) */
}

@media (max-width: 992px) {
  /* Large devices (desktops, 992px and down) */
}

@media (max-width: 768px) {
  /*  Medium devices (tablets, 768px and down) */
  .news-class div {
    max-width: 120px;
  }
  .news-date {
    text-align: left;
    margin-left: 20px;
  }
}

@media (max-width: 576px) {
  /*  Small devices (landscape phones, 576px and down) */
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "solutions-area section-width ptb-100",
      attrs: { id: "news" }
    },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("div", { staticClass: "section-title" }, [
            _c("h2", [_vm._v(_vm._s(_vm.$t("navbar.menu_3_1")))]),
            _c("p", [
              _vm._v(
                " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. "
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "port-filter" },
            [
              _c(
                "a",
                {
                  class: ["filter", { active: _vm.selectGroupId == 0 }],
                  on: {
                    click: function($event) {
                      return _vm.getNewsGroupList(0)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("class")))]
              ),
              _vm._l(_vm.newsGroupNameList, function(item, index) {
                return _c(
                  "a",
                  {
                    key: index,
                    class: ["filter", { active: _vm.selectGroupId == item[0] }],
                    on: {
                      click: function($event) {
                        return _vm.getNewsGroupList(item[0])
                      }
                    }
                  },
                  [_vm._v(_vm._s(item[1]))]
                )
              })
            ],
            2
          ),
          _vm._l(_vm.newsGroupTitleList, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "wow fadeIn pb-30 news-box",
                on: {
                  click: function($event) {
                    return _vm.showNewsModel(item.announcement_message_id)
                  }
                }
              },
              [
                _c("div", { staticClass: "row animated fadeInUp" }, [
                  _c("div", { staticClass: "col-md-2 col-sm-2 news-class" }, [
                    _c(
                      "div",
                      { class: ["news-class-bg" + item.announcement_group_id] },
                      [_vm._v(" " + _vm._s(item.group_name) + " ")]
                    )
                  ]),
                  _c("div", { staticClass: "col-md-2 col-sm-2 news-date" }, [
                    _c("div", [_vm._v(" " + _vm._s(item.post_date) + " ")])
                  ]),
                  _c("div", { staticClass: "col-md-8 col-sm-8 news-title" }, [
                    item.topping == 1
                      ? _c("img", {
                          attrs: {
                            src: require("assets/project_images/pic/newtop.webp")
                          }
                        })
                      : _vm._e(),
                    _c("a", [_vm._v(_vm._s(item.message_title))])
                  ])
                ])
              ]
            )
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShowNextPage,
                  expression: "isShowNextPage"
                }
              ],
              class: ["row", "text-center"]
            },
            [
              _c("div", { staticClass: "col-lg-12 read-more" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning",
                    on: {
                      click: function($event) {
                        return _vm.getNewsGroupList(_vm.selectGroupId)
                      }
                    }
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("pageBtn"))),
                    _c("i", { staticClass: "fas fa-chevron-right" })
                  ]
                )
              ])
            ]
          )
        ],
        2
      ),
      _c("NewsModel", {
        attrs: { newsId: 2, NewsModelList: _vm.NewsModelList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }